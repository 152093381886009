import React from 'react';
import { Drawer, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BottomSheet = ({ children, open, setOpen, closeHandler, isAuthenticated }) => {
  const handleDrawerClose = (event) => {
    if (isAuthenticated) {
      setOpen(false);
      if (closeHandler) {
        closeHandler(event);
      }
    } else {
      event.preventDefault(); // Block closing for unauthenticated users
    }
  };

  return (
    <Drawer
  anchor="bottom"
  open={open}
  onClose={(event) => handleDrawerClose(event)}
  ModalProps={{
    keepMounted: true,
    onBackdropClick: (event) => handleDrawerClose(event),
  }}
  sx={{
    '& .MuiDrawer-paper': {
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
      padding: '10px',
      height: {
        xs: '213px', // Mobile view
        sm: '176px', // Larger screens
      },
      maxHeight: '80vh',
      width: 'auto',
      maxWidth: '100vw',
    },
  }}
>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* Close Button Section */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
  
        </Box>

        {/* Content Section */}
        <Box sx={{ height: '5px', padding: '30px' }}>{children}</Box>
      </Box>
    </Drawer>
  );
};

export default BottomSheet;
