import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  Box,
  Card,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import crossIcon from './../assets/cross.png';
import { calculateDotBalls, convertBallsToOver, fetchWicketFall } from '../helpers/calculationLogics';

const OutTypes = {
  catch: 'C',
  runOut: 'RO',
  continuousBeat: 'BO',
  bowled: 'B',
  stumped: 'ST',
  caughtBehind: 'CB',
  caughtBowled: 'C&B',
  outZone: 'OZ',
};

const ScoreTable = ({ inning, isLive, teamScore, teams }) => {
  const matchData = useSelector((state) => state.match);
  const currScore = matchData.currScore;
  const limitedOver = matchData?.data?.[0]?.info?.[0]?.limitedOver || null;
  let {
    batsManDetail = [],
    bowlerDetail = [],
    inningRun = '0',
    inningWkt = '0',
    inningExtraRuns = '0',
    overDetail = [],
    totalWideBall = '0',
    totalNoBall = '0',
    totalByeRuns = '0',
    totalNoRuns = '0',
    totalWideRuns = '0',
  } = teams
    ? {
        batsManDetail: [],
        bowlerDetail: [],
        inningRun: '0',
        inningWkt: '0',
        inningExtraRuns: '0',
        overDetail: [],
        totalWideBall: '0',
        totalNoBall: '0',
        totalByeRuns: '0',
        totalNoRuns: '0',
        totalWideRuns: '0',
      }
    : isLive
    ? teamScore || {}
    : inning || {};
  const playerDotBallsDetails = isLive ? teamScore?.bowlerDotBalls : calculateDotBalls(overDetail);
  const ballPerOver = matchData?.data?.[0].info?.[0]?.numOfBallsPerOver || 0;
  const isWideExcluded = matchData?.ExcludeWide;
  const isNoBallExcluded = matchData?.ExcludeNoBall;
  const wicketFallDetails = isLive ? teamScore?.fallOfWickets : fetchWicketFall(overDetail);
  const ProfileDetails = matchData?.profileName;
  const PlayerName = currScore?.playerProfiles;
  const overDetails = isLive
    ? `(${teamScore?.inningOver} ov)`
    : inning?.inningOver
    ? `(${inning?.inningOver} ov)`
    : `0/${limitedOver} ov`;
  const showScoreCard = inning ? true : teamScore?.showScoreCard;
  if (isLive) {
    batsManDetail =
      showScoreCard && batsManDetail.length > 0
        ? batsManDetail
        : teamScore?.players.map((player) => ({
            batsmanName: player.playername,
            batsmanRun: '-',
            batsmanBall: '-',
            batsmanFour: '-',
            batsmanSix: '-',
          }));
  } else if (teams) {
    batsManDetail = teams?.players
      .map((player) => ({
        batsmanName: player.playername,
        batsmanRun: '-',
        batsmanBall: '-',
        batsmanFour: '-',
        batsmanSix: '-',
      }))
      .sort((a, b) => a.batsmanName.localeCompare(b.batsmanName));
  }

  const [expandedBatter, setExpandedBatter] = useState(!isLive);
  const [expandedBowler, setExpandedBowler] = useState(!isLive);
  const [expandedFallOfWickets, setExpandedFallOfWickets] = useState(!isLive);

  const handleToggleBatter = () => {
    setExpandedBatter(!expandedBatter);
  };

  const handleToggleBowler = () => {
    setExpandedBowler(!expandedBowler);
  };

  const handleToggleFallOfWickets = () => {
    setExpandedFallOfWickets(!expandedFallOfWickets);
  };

  return (
    <>
      <Box
        sx={{
          gap: 0,
          margin: '16px',
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            padding: '4px 12px 8px 12px',
            gap: '0px',
            borderRadius: '11px 11px 11px 11px',
            border: '1px solid #C8CDF9',
            background: '#FFFFFF',
            boxShadow: '0px 6px 6px 0px #14142B1C',
            marginleft: '28px',
            marginRight: '28px',
          }}
        >
          <Table sx={{ borderCollapse: 'separate', backgroundColor: '#FFFFFF', borderSpacing: '0.25px' }}>
            <TableHead>
              <TableCell colSpan={5} sx={{ padding: 0, borderBottom: 'none' }}>
                <Box
                  sx={{
                    height: '30px',
                    padding: '8px 4px 4px 2px',
                    gap: '8px',
                    border: '2px 2px 2px 2px',
                    color: '#000000',
                    backgroundColor: '#FFFFFF',
                    justifyContent: 'space-between',
                    display: 'flex',
                  }}
                >
                  <Typography
                    sx={{
                      height: '14px',
                      gap: '0px',
                      fontSize: '12px',
                      fontWeight: '600',
                      lineHeight: '14.06px',
                      letterSpacing: '0.5px',
                      textAlign: 'left',
                      color: '#515153',
                    }}
                  >
                    {isLive ? 'CURRENT BATTER DETAILS' : 'BATTER DETAILS'}
                  </Typography>
                  {isLive && (
                    <IconButton onClick={handleToggleBatter}>
                      {expandedBatter ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  )}
                </Box>
              </TableCell>
            </TableHead>
            <TableHead>
              <TableRow sx={{ display: 'flex', justifyContent: 'space-between', gap: '4px', width: '100%' }}>
                <TableCell
                  sx={{
                    width: { xs: '50%', sm: '25%', md: '50%' },
                    height: '20px',
                    padding: '4px 0px 4px 2px',
                    gap: '8px',
                    border: '0px 0px 2px 0px',
                    borderBottom: '2px solid #978FED',
                    color: '#000000',
                    backgroundColor: 'white',
                  }}
                >
                  <Typography
                    sx={{
                      height: '11px',
                      gap: '0px',
                      fontSize: '11px',
                      fontWeight: '500',
                      lineHeight: '11px',
                      textAlign: 'left',
                      color: '#64748B',
                    }}
                  >
                    Batter
                  </Typography>
                </TableCell>
                <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                <TableCell
                  sx={{
                    width: { xs: '10%', sm: '15%', md: '10%' },
                    height: '20px',
                    padding: '4px 2px 4px 2px',
                    gap: '8px',
                    border: '0px 0px 2px 0px',
                    borderBottom: '2px solid #F6CF7D',
                    color: '#000000',
                    backgroundColor: 'white',
                  }}
                >
                  <Typography
                    sx={{
                      height: '11px',
                      gap: '0px',
                      fontSize: '11px',
                      fontWeight: '500',
                      lineHeight: '11px',
                      textAlign: 'center',
                      color: '#64748B',
                    }}
                  >
                    R
                  </Typography>
                </TableCell>
                <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                <TableCell
                  sx={{
                    width: { xs: '10%', sm: '15%', md: '10%' },
                    height: '20px',
                    padding: '4px 2px 4px 2px',
                    gap: '8px',
                    border: '0px 0px 2px 0px',
                    borderBottom: '2px solid #A4DDDC',
                    color: '#000000',
                    backgroundColor: 'white',
                  }}
                >
                  <Typography
                    sx={{
                      height: '11px',
                      gap: '0px',
                      fontSize: '11px',
                      fontWeight: '500',
                      lineHeight: '11px',
                      textAlign: 'center',
                      color: '#64748B',
                    }}
                  >
                    B
                  </Typography>
                </TableCell>
                <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                <TableCell
                  sx={{
                    width: { xs: '10%', sm: '15%', md: '10%' },
                    height: '20px',
                    padding: '4px 2px 4px 2px',
                    gap: '8px',
                    border: '0px 0px 2px 0px',
                    borderBottom: '2px solid #7DADF6',
                    color: '#000000',
                    backgroundColor: 'white',
                  }}
                >
                  <Typography
                    sx={{
                      height: '11px',
                      gap: '0px',
                      fontSize: '11px',
                      fontWeight: '500',
                      lineHeight: '11px',
                      textAlign: 'center',
                      color: '#64748B',
                    }}
                  >
                    4&apos;s
                  </Typography>
                </TableCell>
                <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                <TableCell
                  sx={{
                    width: { xs: '10%', sm: '15%', md: '10%' },
                    height: '20px',
                    padding: '4px 2px 4px 2px',
                    gap: '8px',
                    border: '0px 0px 2px 0px',
                    borderBottom: '2px solid #DDA4A4',
                    color: '#000000',
                    backgroundColor: 'white',
                  }}
                >
                  <Typography
                    sx={{
                      height: '11px',
                      gap: '0px',
                      fontSize: '11px',
                      fontWeight: '500',
                      lineHeight: '11px',
                      textAlign: 'center',
                      color: '#64748B',
                    }}
                  >
                    6&apos;s
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            {isLive && !expandedBatter && (
              <TableBody>
                {batsManDetail &&
                  Array.isArray(batsManDetail) &&
                  batsManDetail
                    .filter(
                      (batsman) =>
                        batsman.batsmanName === teamScore?.onPitch?.striker ||
                        batsman.batsmanName === teamScore?.onPitch?.nonStriker
                    )
                    .map((batsman, index) => (
                      <TableRow
                        key={index}
                        sx={{ display: 'flex', justifyContent: 'space-between', gap: '4px', width: '100%', borderBottom: 'none' }}
                      >

                        <TableCell
                          sx={{
                            width: { xs: '50%', sm: '25%', md: '50%' },
                            backgroundColor: '#FFFFFF',
                            padding: '6px 3px 6px 5px',
                            gap: '8px',
                            borderTop: '1px solid #E2E8F0',
                            borderBottom: 'none',
                            borderLeft: 'none',
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            '::before': {
                              content: '""',
                              position: 'absolute',
                              top: '50%',
                              left: 0,
                              transform: 'translateY(-60%)',
                              width: 5,
                              height: 5,
                              borderLeft:
                                teamScore?.onPitch?.striker === batsman.batsmanName
                                  ? '6px solid #DF513D'
                                  : '6px solid transparent',
                              borderTop: '4px solid transparent',
                              borderBottom: '4px solid transparent',
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: 'uppercase',
                              height: '16px',
                              gap: '0px',
                              fontSize: '11px',
                              fontWeight: '600',
                              lineHeight: '16px',
                              color: '#000000',
                              flex: 1,
                              marginLeft: '4px',
                              alignItems: 'center',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              display: 'flex',
                            }}
                          >
                            <Link
                              to={`/player/${batsman.batsmanName}`}
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              {ProfileDetails?.[batsman.batsmanName]?.name?.firstName
                                ? ProfileDetails[batsman.batsmanName].name.firstName.length > 12
                                  ? ProfileDetails[batsman.batsmanName].name.firstName.slice(0, 12) + '...'
                                  : ProfileDetails[batsman.batsmanName].name.firstName
                                : PlayerName?.[batsman.batsmanName]?.name?.firstName
                                ? PlayerName[batsman.batsmanName].name.firstName.length > 12
                                  ? PlayerName[batsman.batsmanName].name.firstName.slice(0, 12) + '...'
                                  : PlayerName[batsman.batsmanName].name.firstName
                                : batsman.batsmanName}
                            </Link>
                            {batsman.isSuperSub && (
                              <img src={crossIcon} alt="crossicon" style={{ width: '10px', height: '10px', marginLeft: '4px' }} />
                            )}
                          </Typography>
                          {(teamScore?.onPitch?.striker === batsman?.batsmanName ||
                            teamScore?.onPitch?.nonStriker === batsman?.batsmanName) &&
                          batsman?.batsmanOutTag === '' ? (
                            <div
                              style={{
                                height: '6px',
                                color: '#DF513D',
                                fontSize: '9px',
                                marginRight: '3px',
                                lineHeight: '9px',
                              }}
                            >
                              not out
                            </div>
                          ) : batsman?.batsmanOutTag !== '' ? (
                            <div className="flex flex-col md:flex-row space-y-0 md:space-x-2">
                              <span className="flex items-center">
                                <span className="text-[9px] font-bold">{OutTypes?.[batsman?.batsmanOutTag]}</span>
                                <span className="text-[11px] ml-1">
                                  {['catch', 'caughtBehind', 'stumped'].includes(batsman?.batsmanOutTag)
                                    ? ProfileDetails?.[batsman.catchTaken]?.name?.firstName
                                    ? ProfileDetails[batsman.catchTaken].name.firstName.length > 12
                                      ? ProfileDetails[batsman.catchTaken].name.firstName.slice(0, 12) + '...'
                                      : ProfileDetails[batsman.catchTaken].name.firstName
                                    : PlayerName?.[batsman.catchTaken]?.name?.firstName
                                    ? PlayerName[batsman.catchTaken].name.firstName.length > 12
                                      ? PlayerName[batsman.catchTaken].name.firstName.slice(0, 12) + '...'
                                      : PlayerName[batsman.catchTaken].name.firstName
                                    : batsman.catchTaken
                                    : batsman?.batsmanOutTag === 'runOut'
                                    ? ProfileDetails?.[batsman.runOutThrow]?.name?.firstName
                                    ? ProfileDetails[batsman.runOutThrow].name.firstName.length > 12
                                      ? ProfileDetails[batsman.runOutThrow].name.firstName.slice(0, 12) + '...'
                                      : ProfileDetails[batsman.runOutThrow].name.firstName
                                    : PlayerName?.[batsman.runOutThrow]?.name?.firstName
                                    ? PlayerName[batsman.runOutThrow].name.firstName.length > 12
                                      ? PlayerName[batsman.runOutThrow].name.firstName.slice(0, 12) + '...'
                                      : PlayerName[batsman.runOutThrow].name.firstName
                                    : batsman.runOutThrow

                                   : batsman?.batsmanOutTag === 'bowled' || batsman?.batsmanOutTag === 'caughtBowled' || batsman?.batsmanOutTag === 'continuousBeat'
                                    ? ProfileDetails?.[batsman.bowler]?.name?.firstName
                                    ? ProfileDetails[batsman.bowler].name.firstName.length > 12
                                      ? ProfileDetails[batsman.bowler].name.firstName.slice(0, 12) + '...'
                                      : ProfileDetails[batsman.bowler].name.firstName
                                    : PlayerName?.[batsman.bowler]?.name?.firstName
                                    ? PlayerName[batsman.bowler].name.firstName.length > 12
                                      ? PlayerName[batsman.bowler].name.firstName.slice(0, 12) + '...'
                                      : PlayerName[batsman.bowler].name.firstName
                                    : batsman.bowler
                                    : null}
                                </span>
                              </span>

                              <span className="flex items-center">
                                <span className="text-[9px] font-bold">
                                  {['catch', 'stumped', 'caughtBehind', 'runOut'].includes(batsman?.batsmanOutTag) ? 'b' : null}
                                </span>
                                <span className="text-[11px] ml-1">
                                  {['catch', 'stumped', 'caughtBehind', 'runOut'].includes(batsman?.batsmanOutTag)
                                    ? ProfileDetails?.[batsman.bowler]?.name?.firstName
                                      ? ProfileDetails[batsman.bowler].name.firstName.length > 12
                                        ? ProfileDetails[batsman.bowler].name.firstName.slice(0, 12) + '...'
                                        : ProfileDetails[batsman.bowler].name.firstName
                                      : PlayerName?.[batsman.bowler]?.name?.firstName
                                      ? PlayerName[batsman.bowler].name.firstName.length > 12
                                        ? PlayerName[batsman.bowler].name.firstName.slice(0, 12) + '...'
                                        : PlayerName[batsman.bowler].name.firstName
                                      : batsman.bowler
                                    : null}
                                </span>
                              </span>
                            </div>
                          ) : null}
                        </TableCell>
                        <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                        <TableCell
                          sx={{
                            width: { xs: '10%', sm: '15%', md: '10%' },
                            height: '30px',
                            padding: '4px 2px 4px 2px',
                            gap: '8px',
                            borderTop: '1px solid #E2E8F0',
                            borderBottom: 'none',
                            color: '#000000',
                            backgroundColor: 'white',
                          }}
                        >
                          <Typography
                            sx={{
                              height: '16px',
                              gap: '0px',
                              fontSize: '11px',
                              fontWeight: '600',
                              lineHeight: '16px',
                              textAlign: 'center',
                              color: '#000000',
                            }}
                          >
                            {batsman.batsmanRun}
                          </Typography>
                        </TableCell>
                        <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                        <TableCell
                          sx={{
                            width: { xs: '10%', sm: '15%', md: '10%' },
                            height: '30px',
                            padding: '4px 2px 4px 2px',
                            gap: '8px',
                            borderTop: '1px solid #E2E8F0',
                            borderBottom: 'none',
                            color: '#000000',
                            backgroundColor: 'white',
                          }}
                        >
                          <Typography
                            sx={{
                              height: '16px',
                              gap: '0px',
                              fontSize: '11px',
                              fontWeight: '600',
                              lineHeight: '16px',
                              textAlign: 'center',
                              color: '#000000',
                            }}
                          >
                            {batsman.batsmanBall}
                          </Typography>
                        </TableCell>
                        <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                        <TableCell
                          sx={{
                            width: { xs: '10%', sm: '15%', md: '10%' },
                            height: '30px',
                            padding: '4px 2px 4px 2px',
                            gap: '8px',
                            borderTop: '1px solid #E2E8F0',
                            borderBottom: 'none',
                            color: '#000000',
                            backgroundColor: 'white',
                          }}
                        >
                          <Typography
                            sx={{
                              height: '16px',
                              gap: '0px',
                              fontSize: '11px',
                              fontWeight: '600',
                              lineHeight: '16px',
                              textAlign: 'center',
                              color: '#000000',
                            }}
                          >
                            {batsman.batsmanFour}
                          </Typography>
                        </TableCell>
                        <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                        <TableCell
                          sx={{
                            width: { xs: '10%', sm: '15%', md: '10%' },
                            height: '30px',
                            padding: '4px 2px 4px 2px',
                            gap: '8px',
                            borderTop: '1px solid #E2E8F0',
                            borderRight: 'none',
                            borderBottom: 'none',
                            color: '#000000',
                            backgroundColor: 'white',
                          }}
                        >
                          <Typography
                            sx={{
                              height: '16px',
                              gap: '0px',
                              fontSize: '11px',
                              fontWeight: '600',
                              lineHeight: '16px',
                              textAlign: 'center',
                              color: '#000000',
                            }}
                          >
                            {batsman.batsmanSix}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            )}
            {expandedBatter && (
              <TableBody>
                {batsManDetail &&
                  Array.isArray(batsManDetail) &&
                  batsManDetail
                    .filter((batsman) => batsman?.batsmanName)
                    .sort((a, b) => {
                      if (a.batOrderId === '0' && b.batOrderId !== '0') return 1;
                      if (a.batOrderId !== '0' && b.batOrderId === '0') return -1;
                      return a.batOrderId - b.batOrderId;
                    })
                    .map((batsman, index) => (
                      <TableRow
                        key={index}
                        sx={{ display: 'flex', justifyContent: 'space-between', gap: '4px', width: '100%', borderBottom: 'none' }}
                      >
                        <TableCell
                          sx={{
                            width: { xs: '50%', sm: '25%', md: '50%' },
                            backgroundColor: '#FFFFFF',
                            padding: '6px 3px 6px 5px',
                            gap: '8px',
                            borderTop: '1px solid #E2E8F0',
                            borderBottom: 'none',
                            borderLeft: 'none',
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            '::before': {
                              content: '""',
                              position: 'absolute',
                              top: '50%',
                              left: 0,
                              transform: 'translateY(-60%)',
                              width: 5,
                              height: 5,
                              borderLeft:
                                isLive && teamScore?.onPitch?.striker === batsman.batsmanName
                                  ? '6px solid #DF513D'
                                  : '6px solid transparent',
                              borderTop: '4px solid transparent',
                              borderBottom: '4px solid transparent',
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: 'uppercase',
                              height: '16px',
                              gap: '0px',
                              fontSize: '11px',
                              fontWeight: '600',
                              lineHeight: '16px',
                              fontcolor: '#000000',
                              flex: 1,
                              marginLeft: '4px',
                              display: 'flex',
                              alignItems: 'center',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            <Link
                              to={`/player/${batsman.batsmanName}`}
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              {ProfileDetails?.[batsman.batsmanName]?.name?.firstName
                                ? ProfileDetails[batsman.batsmanName].name.firstName.length > 12
                                  ? ProfileDetails[batsman.batsmanName].name.firstName.slice(0, 12) + '...'
                                  : ProfileDetails[batsman.batsmanName].name.firstName
                                : PlayerName?.[batsman.batsmanName]?.name?.firstName
                                ? PlayerName[batsman.batsmanName].name.firstName.length > 12
                                  ? PlayerName[batsman.batsmanName].name.firstName.slice(0, 12) + '...'
                                  : PlayerName[batsman.batsmanName].name.firstName
                                : batsman.batsmanName}
                            </Link>
                            {batsman.isSuperSub && (
                              <img src={crossIcon} alt="crossicon" style={{ width: '10px', height: '10px', marginLeft: '3px' }} />
                            )}
                          </Typography>
                          {isLive ? (
                            (teamScore?.onPitch?.striker === batsman.batsmanName ||
                              teamScore?.onPitch?.nonStriker === batsman.batsmanName) &&
                            batsman?.batsmanOutTag === '' ? (
                              <div
                                style={{
                                  height: '6px',
                                  color: '#DF513D',
                                  fontSize: '9px',
                                  marginRight: '3px',
                                  lineHeight: '9px',
                                }}
                              >
                                not out
                              </div>
                            ) : batsman?.batsmanOutTag !== '' ? (
                              <div className="flex flex-col md:flex-row space-y-0 md:space-x-2">
                                <span className="flex items-center">
                                  <span className="text-[9px] font-bold">{OutTypes[batsman?.batsmanOutTag]}</span>
                                  <span className="text-[11px] ml-1">
                                    {batsman?.batsmanOutTag === 'catch' ||
                                    batsman?.batsmanOutTag === 'caughtBehind' ||
                                    batsman?.batsmanOutTag === 'stumped'
                                      ? ProfileDetails?.[batsman.catchTaken]?.name?.firstName
                                      ? ProfileDetails[batsman.catchTaken].name.firstName.length > 12
                                        ? ProfileDetails[batsman.catchTaken].name.firstName.slice(0, 12) + '...'
                                        : ProfileDetails[batsman.catchTaken].name.firstName
                                      : PlayerName?.[batsman.catchTaken]?.name?.firstName
                                      ? PlayerName[batsman.catchTaken].name.firstName.length > 12
                                        ? PlayerName[batsman.catchTaken].name.firstName.slice(0, 12) + '...'
                                        : PlayerName[batsman.catchTaken].name.firstName
                                      : batsman.catchTaken
                                      : batsman?.batsmanOutTag === 'runOut'
                                      ? ProfileDetails?.[batsman.runOutThrow]?.name?.firstName
                                      ? ProfileDetails[batsman.runOutThrow].name.firstName.length > 12
                                        ? ProfileDetails[batsman.runOutThrow].name.firstName.slice(0, 12) + '...'
                                        : ProfileDetails[batsman.runOutThrow].name.firstName
                                      : PlayerName?.[batsman.runOutThrow]?.name?.firstName
                                      ? PlayerName[batsman.runOutThrow].name.firstName.length > 12
                                        ? PlayerName[batsman.runOutThrow].name.firstName.slice(0, 12) + '...'
                                        : PlayerName[batsman.runOutThrow].name.firstName
                                      : batsman.runOutThrow
                                      : batsman?.batsmanOutTag === 'bowled' || batsman?.batsmanOutTag === 'caughtBowled' || batsman?.batsmanOutTag === 'continuousBeat'
                                      ? ProfileDetails?.[batsman.bowler]?.name?.firstName
                                      ? ProfileDetails[batsman.bowler].name.firstName.length > 12
                                        ? ProfileDetails[batsman.bowler].name.firstName.slice(0, 12) + '...'
                                        : ProfileDetails[batsman.bowler].name.firstName
                                      : PlayerName?.[batsman.bowler]?.name?.firstName
                                      ? PlayerName[batsman.bowler].name.firstName.length > 12
                                        ? PlayerName[batsman.bowler].name.firstName.slice(0, 12) + '...'
                                        : PlayerName[batsman.bowler].name.firstName
                                      : batsman.bowler
                                      : null}
                                  </span>
                                </span>

                                <span className="flex items-center">
                                  <span className="text-[9px] font-bold">
                                    {batsman?.batsmanOutTag === 'catch' ||
                                    batsman?.batsmanOutTag === 'stumped' ||
                                    batsman?.batsmanOutTag === 'caughtBehind' ||
                                    batsman?.batsmanOutTag === 'runOut'
                                      ? 'b'
                                      : null}
                                  </span>
                                  <span className="text-[11px] ml-1">
                                    {batsman?.batsmanOutTag === 'catch' ||
                                    batsman?.batsmanOutTag === 'stumped' ||
                                    batsman?.batsmanOutTag === 'caughtBehind' ||
                                    batsman?.batsmanOutTag === 'runOut'
                                      ? ProfileDetails?.[batsman.bowler]?.name?.firstName
                                      ? ProfileDetails[batsman.bowler].name.firstName.length > 12
                                        ? ProfileDetails[batsman.bowler].name.firstName.slice(0, 12) + '...'
                                        : ProfileDetails[batsman.bowler].name.firstName
                                      : PlayerName?.[batsman.bowler]?.name?.firstName
                                      ? PlayerName[batsman.bowler].name.firstName.length > 12
                                        ? PlayerName[batsman.bowler].name.firstName.slice(0, 12) + '...'
                                        : PlayerName[batsman.bowler].name.firstName
                                      : batsman.bowler
                                      : null}
                                  </span>
                                </span>
                              </div>
                            ) : null
                          ) : batsman?.batsmanBall === '0' ? (
                            <div
                              style={{
                                color: '#DF513D',
                                fontSize: '9px',
                                marginRight: '3px',
                                lineHeight: '9px',
                              }}
                            >
                              DNB
                            </div>
                          ) : batsman?.batsmanOutTag !== '' ? (
                            <div className="flex flex-col md:flex-row space-y-0 md:space-x-2">
                              <span className="flex items-center">
                                <span className="text-[9px] font-bold">{OutTypes[batsman?.batsmanOutTag]}</span>
                                <span className="text-[11px] ml-1">
                                  {batsman?.batsmanOutTag === 'catch' ||
                                  batsman?.batsmanOutTag === 'caughtBehind' ||
                                  batsman?.batsmanOutTag === 'stumped'
                                    ?ProfileDetails?.[batsman.catchTaken]?.name?.firstName
                                    ? ProfileDetails[batsman.catchTaken].name.firstName.length > 12
                                      ? ProfileDetails[batsman.catchTaken].name.firstName.slice(0, 12) + '...'
                                      : ProfileDetails[batsman.catchTaken].name.firstName
                                    : PlayerName?.[batsman.catchTaken]?.name?.firstName
                                    ? PlayerName[batsman.catchTaken].name.firstName.length > 12
                                      ? PlayerName[batsman.catchTaken].name.firstName.slice(0, 12) + '...'
                                      : PlayerName[batsman.catchTaken].name.firstName
                                    : batsman.catchTaken
                                    : batsman?.batsmanOutTag === 'runOut'
                                    ? ProfileDetails?.[batsman.runOutThrow]?.name?.firstName
                                    ? ProfileDetails[batsman.runOutThrow].name.firstName.length > 12
                                      ? ProfileDetails[batsman.runOutThrow].name.firstName.slice(0, 12) + '...'
                                      : ProfileDetails[batsman.runOutThrow].name.firstName
                                    : PlayerName?.[batsman.runOutThrow]?.name?.firstName
                                    ? PlayerName[batsman.runOutThrow].name.firstName.length > 12
                                      ? PlayerName[batsman.runOutThrow].name.firstName.slice(0, 12) + '...'
                                      : PlayerName[batsman.runOutThrow].name.firstName
                                    : batsman.runOutThrow
                                    : batsman?.batsmanOutTag === 'bowled' || batsman?.batsmanOutTag === 'caughtBowled' || batsman?.batsmanOutTag === 'continuousBeat'
                                    ? ProfileDetails?.[batsman.bowler]?.name?.firstName
                                    ? ProfileDetails[batsman.bowler].name.firstName.length > 12
                                      ? ProfileDetails[batsman.bowler].name.firstName.slice(0, 12) + '...'
                                      : ProfileDetails[batsman.bowler].name.firstName
                                    : PlayerName?.[batsman.bowler]?.name?.firstName
                                    ? PlayerName[batsman.bowler].name.firstName.length > 12
                                      ? PlayerName[batsman.bowler].name.firstName.slice(0, 12) + '...'
                                      : PlayerName[batsman.bowler].name.firstName
                                    : batsman.bowler
                                    : null}
                                </span>         
                              </span>

                              <span className="flex items-center">
                                <span className="text-[9px] font-bold">
                                  {batsman?.batsmanOutTag === 'catch' ||
                                  batsman?.batsmanOutTag === 'stumped' ||
                                  batsman?.batsmanOutTag === 'caughtBehind' ||
                                  batsman?.batsmanOutTag === 'runOut'
                                    ? 'b'
                                    : null}
                                </span>
                                <span className="text-[11px] ml-1">
                                  {batsman?.batsmanOutTag === 'catch' ||
                                  batsman?.batsmanOutTag === 'stumped' ||
                                  batsman?.batsmanOutTag === 'caughtBehind' ||
                                  batsman?.batsmanOutTag === 'runOut'
                                    ? ProfileDetails?.[batsman.bowler]?.name?.firstName
                                    ? ProfileDetails[batsman.bowler].name.firstName.length > 12
                                      ? ProfileDetails[batsman.bowler].name.firstName.slice(0, 12) + '...'
                                      : ProfileDetails[batsman.bowler].name.firstName
                                    : PlayerName?.[batsman.bowler]?.name?.firstName
                                    ? PlayerName[batsman.bowler].name.firstName.length > 12
                                      ? PlayerName[batsman.bowler].name.firstName.slice(0, 12) + '...'
                                      : PlayerName[batsman.bowler].name.firstName
                                    : batsman.bowler
                                    : null}
                                </span>
                              </span>
                            </div>
                          ) : (
                            <div
                              style={{
                                color: '#DF513D',
                                fontSize: '9px',
                                marginRight: '3px',
                                lineHeight: '9px',
                              }}
                            >
                              not out
                            </div>
                          )}
                        </TableCell>
                        <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                        <TableCell
                          sx={{
                            width: { xs: '10%', sm: '15%', md: '10%' },
                            height: '30px',
                            padding: '4px 2px 4px 2px',
                            gap: '8px',
                            borderTop: '1px solid #E2E8F0',
                            borderBottom: 'none',
                            color: '#000000',
                            backgroundColor: 'white',
                          }}
                        >
                          <Typography
                            sx={{
                              height: '16px',
                              gap: '0px',
                              fontSize: '11px',
                              fontWeight: '600',
                              lineHeight: '16px',
                              textAlign: 'center',
                              color: '#000000',
                            }}
                          >
                            {batsman.batsmanRun}
                          </Typography>
                        </TableCell>
                        <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                        <TableCell
                          sx={{
                            width: { xs: '10%', sm: '15%', md: '10%' },
                            height: '30px',
                            padding: '4px 2px 4px 2px',
                            gap: '8px',
                            borderTop: '1px solid #E2E8F0',
                            borderBottom: 'none',
                            color: '#000000',
                            backgroundColor: 'white',
                          }}
                        >
                          <Typography
                            sx={{
                              height: '16px',
                              gap: '0px',
                              fontSize: '11px',
                              fontWeight: '600',
                              lineHeight: '16px',
                              textAlign: 'center',
                              color: '#000000',
                            }}
                          >
                            {batsman.batsmanBall}
                          </Typography>
                        </TableCell>
                        <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                        <TableCell
                          sx={{
                            width: { xs: '10%', sm: '15%', md: '10%' },
                            height: '30px',
                            padding: '4px 2px 4px 2px',
                            gap: '8px',
                            borderTop: '1px solid #E2E8F0',
                            borderBottom: 'none',
                            color: '#000000',
                            backgroundColor: 'white',
                          }}
                        >
                          <Typography
                            sx={{
                              height: '16px',
                              gap: '0px',
                              fontSize: '11px',
                              fontWeight: '600',
                              lineHeight: '16px',
                              textAlign: 'center',
                              color: '#000000',
                            }}
                          >
                            {batsman.batsmanFour}
                          </Typography>
                        </TableCell>
                        <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                        <TableCell
                          sx={{
                            width: { xs: '10%', sm: '15%', md: '10%' },
                            height: '30px',
                            padding: '4px 2px 4px 2px',
                            gap: '8px',
                            borderTop: '1px solid #E2E8F0',
                            borderBottom: 'none',
                            color: '#000000',
                            backgroundColor: 'white',
                          }}
                        >
                          <Typography
                            sx={{
                              height: '16px',
                              gap: '0px',
                              fontSize: '11px',
                              fontWeight: '600',
                              lineHeight: '16px',
                              textAlign: 'center',
                              color: '#000000',
                            }}
                          >
                            {batsman.batsmanSix}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            )}
          </Table>
          <Box
            sx={{
              height: '30px',
              padding: '6px 11px',
              gap: '0px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#E9E8FC',
              margin: '2px 2px',
            }}
          >
            <Typography
              sx={{
                height: '16px',
                gap: '0px',
                fontSize: '12px',
                fontWeight: '600',
                lineHeight: '16px',
                textAlign: 'left',
                color: '#000000',
              }}
            >
              Total Score
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '12px',
                  color: '#000000',
                }}
              >
                {isLive ? teamScore?.inningScore : `${inningRun}/${inningWkt}`}
              </Typography>
              {overDetails ? (
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: '11px',
                  }}
                >
                  {overDetails}
                </Typography>
              ) : null}
            </Box>
          </Box>
          <Card sx={{ py: 1 }}>
            <Container
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '12px',
                }}
              >
                Extras
              </Typography>
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '11px',
                }}
              >
                {inningExtraRuns} | W {totalWideRuns || 0} | NB {totalNoRuns || 0} | LB {totalByeRuns || 0}
              </Typography>
            </Container>
          </Card>
        </TableContainer>
      </Box>
      {showScoreCard && (
        <>
          <Box
            sx={{
              gap: 0,
              margin: '16px',
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                padding: '4px 12px 8px 12px',
                gap: '0px',
                borderRadius: '11px 11px 11px 11px',
                border: '1px solid #C8CDF9',
                background: '#FFFFFF',
                boxShadow: '0px 6px 6px 0px #14142B1C',
                marginleft: '28px',
                marginRight: '28px',
              }}
            >
              <Table sx={{ borderCollapse: 'separate', backgroundColor: '#FFFFFF', borderSpacing: '0.25px' }}>
                <TableHead>
                  <TableCell colSpan={5} sx={{ padding: 0, borderBottom: 'none' }}>
                    <Box
                      sx={{
                        height: '30px',
                        padding: '8px 4px 4px 2px',
                        gap: '8px',
                        border: '2px 2px 2px 2px',
                        color: '#000000',
                        backgroundColor: '#FFFFFF',
                        justifyContent: 'space-between',
                        display: 'flex',
                      }}
                    >
                      <Typography
                        sx={{
                          height: '14px',
                          gap: '0px',
                          fontSize: '12px',
                          fontWeight: '600',
                          lineHeight: '14.06px',
                          letterSpacing: '0.5px',
                          textAlign: 'left',
                          color: '#515153',
                        }}
                      >
                        {isLive ? 'CURRENT BOWLER DETAILS' : 'BOWLER DETAILS'}
                      </Typography>
                      {isLive && (
                        <IconButton onClick={handleToggleBowler}>
                          {expandedBowler ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      )}
                    </Box>
                  </TableCell>
                </TableHead>
                <TableRow
                  sx={{ display: 'flex', justifyContent: 'space-between', gap: '4px', width: '100%', borderBottom: 'none' }}
                >
                  <TableCell
                    sx={{
                      width: { xs: '50%', sm: '25%', md: '50%' },
                      height: '20px',
                      padding: '4px 0px 4px 2px',
                      gap: '8px',
                      border: '0px 0px 2px 0px',
                      borderBottom: '2px solid #978FED',
                      color: '#000000',
                      backgroundColor: 'white',
                    }}
                  >
                    <Typography
                      sx={{
                        height: '11px',
                        gap: '0px',
                        fontSize: '11px',
                        fontWeight: '500',
                        lineHeight: '11px',
                        textAlign: 'left',
                        color: '#64748B',
                      }}
                    >
                      Bowler
                    </Typography>
                  </TableCell>
                  <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                  <TableCell
                    sx={{
                      width: { xs: '10%', sm: '15%', md: '10%' },
                      height: '20px',
                      padding: '4px 2px 4px 2px',
                      gap: '8px',
                      border: '0px 0px 2px 0px',
                      borderBottom: '2px solid #A4DDDC',
                      color: '#000000',
                      backgroundColor: 'white',
                    }}
                  >
                    <Typography
                      sx={{
                        height: '11px',
                        gap: '0px',
                        fontSize: '11px',
                        fontWeight: '500',
                        lineHeight: '11px',
                        textAlign: 'center',
                        color: '#64748B',
                      }}
                    >
                      B
                    </Typography>
                  </TableCell>
                  <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                  <TableCell
                    sx={{
                      width: { xs: '10%', sm: '15%', md: '10%' },
                      height: '20px',
                      padding: '4px 2px 4px 2px',
                      gap: '8px',
                      border: '0px 0px 2px 0px',
                      borderBottom: '2px solid #F6CF7D',
                      color: '#000000',
                      backgroundColor: 'white',
                    }}
                  >
                    <Typography
                      sx={{
                        height: '11px',
                        gap: '0px',
                        fontSize: '11px',
                        fontWeight: '500',
                        lineHeight: '11px',
                        textAlign: 'center',
                        color: '#64748B',
                      }}
                    >
                      R
                    </Typography>
                  </TableCell>
                  <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                  <TableCell
                    sx={{
                      width: { xs: '10%', sm: '15%', md: '10%' },
                      height: '20px',
                      padding: '4px 2px 4px 2px',
                      gap: '8px',
                      border: '0px 0px 2px 0px',
                      borderBottom: '2px solid #E5796A',
                      color: '#000000',
                      backgroundColor: 'white',
                    }}
                  >
                    <Typography
                      sx={{
                        height: '11px',
                        gap: '0px',
                        fontSize: '11px',
                        fontWeight: '500',
                        lineHeight: '11px',
                        textAlign: 'center',
                        color: '#64748B',
                      }}
                    >
                      W
                    </Typography>
                  </TableCell>
                  <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                  <TableCell
                    sx={{
                      width: { xs: '10%', sm: '15%', md: '10%' },
                      height: '20px',
                      padding: '4px 2px 4px 2px',
                      gap: '8px',
                      border: '0px 0px 2px 0px',
                      borderBottom: '2px solid #8A98F1',
                      color: '#000000',
                      backgroundColor: 'white',
                    }}
                  >
                    <Typography
                      sx={{
                        height: '11px',
                        gap: '0px',
                        fontSize: '11px',
                        fontWeight: '500',
                        lineHeight: '11px',
                        textAlign: 'center',
                        color: '#64748B',
                      }}
                    >
                      Db
                    </Typography>
                  </TableCell>
                </TableRow>
                {isLive && !expandedBowler && (
                  <TableBody>
                    {bowlerDetail
                      .filter((bowler) => bowler.bowlerName === teamScore?.onPitch?.bowler)
                      .map((bowler, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '4px',
                            width: '100%',
                            borderBottom: 'none',
                          }}
                        >
                          <TableCell
                            sx={{
                              width: { xs: '50%', sm: '25%', md: '50%' },
                              height: '30px',
                              backgroundColor: '#FFFFFF',
                              padding: '6px 3px 6px 5px',
                              gap: '8px',
                              borderTop: '1px solid #E2E8F0',
                              borderBottom: 'none',
                              borderLeft: 'none',
                              position: 'relative',
                              display: 'flex',
                              alignItems: 'center',
                              '::before': {
                                content: '""',
                                position: 'absolute',
                                top: '50%',
                                left: 0,
                                transform: 'translateY(-60%)',
                                width: 5,
                                height: 5,
                                borderLeft: '6px solid #DF513D',
                                borderTop: '4px solid transparent',
                                borderBottom: '4px solid transparent',
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                textTransform: 'uppercase',
                                height: '16px',
                                gap: '0px',
                                fontSize: '11px',
                                fontWeight: '600',
                                lineHeight: '16px',
                                color: '#000000',
                                flex: 1,
                                marginLeft: '4px',
                                display: 'flex',
                                alignItems: 'center',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              <Link
                                to={`/player/${bowler.bowlerName}`}
                                style={{
                                  textDecoration: 'none',
                                  color: 'inherit',
                                }}
                              >
                                {ProfileDetails?.[bowler.bowlerName]?.name?.firstName
                                  ? ProfileDetails[bowler.bowlerName].name.firstName.length > 12
                                    ? ProfileDetails[bowler.bowlerName].name.firstName.slice(0, 12) + '...'
                                    : ProfileDetails[bowler.bowlerName].name.firstName
                                  : PlayerName?.[bowler.bowlerName]?.name?.firstName
                                  ? PlayerName[bowler.bowlerName].name.firstName.length > 12
                                    ? PlayerName[bowler.bowlerName].name.firstName.slice(0, 12) + '...'
                                    : PlayerName[bowler.bowlerName].name.firstName
                                  : bowler.bowlerName}
                              </Link>
                              {bowler.isSuperSub && (
                                <img
                                  src={crossIcon}
                                  alt="crossicon"
                                  style={{ width: '10px', height: '10px', marginLeft: '3px' }}
                                />
                              )}
                            </Typography>
                          </TableCell>
                          <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                          <TableCell
                            sx={{
                              width: { xs: '10%', sm: '15%', md: '10%' },
                              height: '30px',
                              padding: '4px 2px 4px 2px',
                              gap: '8px',
                              borderTop: '1px solid #E2E8F0',
                              borderBottom: 'none',
                              color: '#000000',
                              backgroundColor: 'white',
                            }}
                          >
                            <Typography
                              sx={{
                                height: '16px',
                                gap: '0px',
                                fontSize: '11px',
                                fontWeight: '600',
                                lineHeight: '16px',
                                textAlign: 'center',
                                color: '#000000',
                              }}
                            >
                              {bowler.bowlerBall}
                            </Typography>
                          </TableCell>
                          <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                          <TableCell
                            sx={{
                              width: { xs: '10%', sm: '15%', md: '10%' },
                              height: '30px',
                              padding: '4px 2px 4px 2px',
                              gap: '8px',
                              borderTop: '1px solid #E2E8F0',
                              borderBottom: 'none',
                              color: '#000000',
                              backgroundColor: 'white',
                            }}
                          >
                            <Typography
                              sx={{
                                height: '16px',
                                gap: '0px',
                                fontSize: '11px',
                                fontWeight: '600',
                                lineHeight: '16px',
                                textAlign: 'center',
                                color: '#000000',
                              }}
                            >
                              {bowler.bowlerRun}
                            </Typography>
                          </TableCell>
                          <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                          <TableCell
                            sx={{
                              width: { xs: '10%', sm: '15%', md: '10%' },
                              height: '30px',
                              padding: '4px 2px 4px 2px',
                              gap: '8px',
                              borderTop: '1px solid #E2E8F0',
                              borderBottom: 'none',
                              color: '#000000',
                              backgroundColor: 'white',
                            }}
                          >
                            <Typography
                              sx={{
                                height: '16px',
                                gap: '0px',
                                fontSize: '11px',
                                fontWeight: '600',
                                lineHeight: '16px',
                                textAlign: 'center',
                                color: '#000000',
                              }}
                            >
                              {bowler.bowlerWkt}
                            </Typography>
                          </TableCell>
                          <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                          <TableCell
                            sx={{
                              width: { xs: '10%', sm: '15%', md: '10%' },
                              height: '30px',
                              padding: '4px 2px 4px 2px',
                              gap: '8px',
                              borderTop: '1px solid #E2E8F0',
                              borderBottom: 'none',
                              color: '#000000',
                              backgroundColor: 'white',
                            }}
                          >
                            <Typography
                              sx={{
                                height: '16px',
                                gap: '0px',
                                fontSize: '11px',
                                fontWeight: '600',
                                lineHeight: '16px',
                                textAlign: 'center',
                                color: '#000000',
                              }}
                            >
                              {playerDotBallsDetails?.[bowler.bowlerName] || 0}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                )}
                {expandedBowler && (
                  <TableBody>
                    {bowlerDetail
                      .filter((bowler) => bowler?.bowlerBall > 0)
                      .map(
                        (bowler, index) =>
                          bowler?.bowlerName && (
                            <TableRow
                              key={index}
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '4px',
                                width: '100%',
                                borderBottom: 'none',
                              }}
                            >
                              <TableCell
                                sx={{
                                  width: { xs: '50%', sm: '25%', md: '50%' },
                                  height: '30px',
                                  backgroundColor: '#FFFFFF',
                                  padding: '6px 3px 6px 5px',
                                  gap: '8px',
                                  borderTop: '1px solid #E2E8F0',
                                  borderBottom: 'none',
                                  borderLeft: 'none',
                                  position: 'relative',
                                  display: 'flex',
                                  alignItems: 'center',
                                  '::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: '50%',
                                    left: 0,
                                    transform: 'translateY(-60%)',
                                    width: 5,
                                    height: 5,
                                    borderLeft:
                                      isLive && teamScore?.onPitch?.bowler === bowler.bowlerName
                                        ? '6px solid #DF513D'
                                        : 'transparent',
                                    borderTop: '4px solid transparent',
                                    borderBottom: '4px solid transparent',
                                  },
                                }}
                              >
                                <Typography
                                  sx={{
                                    textTransform: 'uppercase',
                                    height: '16px',
                                    gap: '0px',
                                    fontSize: '11px',
                                    fontWeight: '600',
                                    lineHeight: '16px',
                                    color: '#000000',
                                    flex: 1,
                                    marginLeft: '4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  <Link
                                    to={`/player/${bowler.bowlerName}`}
                                    style={{
                                      textDecoration: 'none',
                                      color: 'inherit',
                                    }}
                                  >
                                    {ProfileDetails?.[bowler.bowlerName]?.name?.firstName
                                      ? ProfileDetails[bowler.bowlerName].name.firstName.length > 12
                                        ? ProfileDetails[bowler.bowlerName].name.firstName.slice(0, 12) + '...'
                                        : ProfileDetails[bowler.bowlerName].name.firstName
                                      : PlayerName?.[bowler.bowlerName]?.name?.firstName
                                      ? PlayerName[bowler.bowlerName].name.firstName.length > 12
                                        ? PlayerName[bowler.bowlerName].name.firstName.slice(0, 12) + '...'
                                        : PlayerName[bowler.bowlerName].name.firstName
                                      : bowler.bowlerName}
                                  </Link>
                                  {bowler.isSuperSub && (
                                    <img
                                      src={crossIcon}
                                      alt="crossicon"
                                      style={{ width: '10px', height: '10px', marginLeft: '3px' }}
                                    />
                                  )}
                                </Typography>
                              </TableCell>
                              <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                              <TableCell
                                sx={{
                                  width: { xs: '10%', sm: '15%', md: '10%' },
                                  height: '30px',
                                  padding: '4px 2px 4px 2px',
                                  gap: '8px',
                                  borderTop: '1px solid #E2E8F0',
                                  borderBottom: 'none',
                                  color: '#000000',
                                  backgroundColor: 'white',
                                }}
                              >
                                <Typography
                                  sx={{
                                    height: '16px',
                                    gap: '0px',
                                    fontSize: '11px',
                                    fontWeight: '600',
                                    lineHeight: '16px',
                                    textAlign: 'center',
                                    color: '#000000',
                                  }}
                                >
                                  {bowler.bowlerBall}
                                </Typography>
                              </TableCell>
                              <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                              <TableCell
                                sx={{
                                  width: { xs: '10%', sm: '15%', md: '10%' },
                                  height: '30px',
                                  padding: '4px 2px 4px 2px',
                                  gap: '8px',
                                  borderTop: '1px solid #E2E8F0',
                                  borderBottom: 'none',
                                  color: '#000000',
                                  backgroundColor: 'white',
                                }}
                              >
                                <Typography
                                  sx={{
                                    height: '16px',
                                    gap: '0px',
                                    fontSize: '11px',
                                    fontWeight: '600',
                                    lineHeight: '16px',
                                    textAlign: 'center',
                                    color: '#000000',
                                  }}
                                >
                                  {bowler.bowlerRun}
                                </Typography>
                              </TableCell>
                              <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                              <TableCell
                                sx={{
                                  width: { xs: '10%', sm: '15%', md: '10%' },
                                  height: '30px',
                                  padding: '4px 2px 4px 2px',
                                  gap: '8px',
                                  borderTop: '1px solid #E2E8F0',
                                  borderBottom: 'none',
                                  color: '#000000',
                                  backgroundColor: 'white',
                                }}
                              >
                                <Typography
                                  sx={{
                                    height: '16px',
                                    gap: '0px',
                                    fontSize: '11px',
                                    fontWeight: '600',
                                    lineHeight: '16px',
                                    textAlign: 'center',
                                    color: '#000000',
                                  }}
                                >
                                  {bowler.bowlerWkt}
                                </Typography>
                              </TableCell>
                              <Box sx={{ width: '1.5px', bgcolor: '#E2E8F0' }} />
                              <TableCell
                                sx={{
                                  width: { xs: '10%', sm: '15%', md: '10%' },
                                  height: '30px',
                                  padding: '4px 2px 4px 2px',
                                  gap: '8px',
                                  borderTop: '1px solid #E2E8F0',
                                  borderBottom: 'none',
                                  color: '#000000',
                                  backgroundColor: 'white',
                                }}
                              >
                                <Typography
                                  sx={{
                                    height: '16px',
                                    gap: '0px',
                                    fontSize: '11px',
                                    fontWeight: '600',
                                    lineHeight: '16px',
                                    textAlign: 'center',
                                    color: '#000000',
                                  }}
                                >
                                  {playerDotBallsDetails?.[bowler.bowlerName] || 0}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              gap: 0,
              margin: '16px',
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                padding: '4px 12px 8px 12px',
                gap: '0px',
                borderRadius: '11px 11px 11px 11px',
                border: '1px solid #C8CDF9',
                background: '#FFFFFF',
                boxShadow: '0px 6px 6px 0px #14142B1C',
                marginleft: '28px',
                marginRight: '28px',
              }}
            >
              <Table sx={{ borderCollapse: 'separate', backgroundColor: '#FFFFFF', borderSpacing: '0.25px' }}>
                <TableHead>
                  <Box
                    sx={{
                      height: '30px',
                      padding: '8px 4px 4px 2px',
                      gap: '8px',
                      border: '2px 2px 2px 2px',
                      color: '#000000',
                      backgroundColor: '#FFFFFF',
                      justifyContent: 'space-between',
                      display: 'flex',
                    }}
                  >
                    <Typography
                      sx={{
                        height: '14px',
                        gap: '0px',
                        fontSize: '12px',
                        fontWeight: '600',
                        lineHeight: '14.06px',
                        letterSpacing: '0.5px',
                        textAlign: 'left',
                        color: '#515153',
                      }}
                    >
                      FALL OF WICKETS
                    </Typography>
                    {isLive && (
                      <IconButton onClick={handleToggleFallOfWickets}>
                        {expandedFallOfWickets ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    )}
                  </Box>
                </TableHead>
                {expandedFallOfWickets && (
                  <Card sx={{ display: 'flex', px: 2, py: 1, flexWrap: 'wrap' }}>
                    {wicketFallDetails && wicketFallDetails.length ? (
                      wicketFallDetails.map((wicket, index) => (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            lineHeight: '14px',
                          }}
                          key={index}
                        >
                          {`${wicket.currentRun} (${wicket.batsman} ${convertBallsToOver(
                            wicket.ballCount,
                            Number(ballPerOver)
                          )} ov) ${index === wicketFallDetails.length - 1 ? '' : ','}`}
                        </Typography>
                      ))
                    ) : (
                      <Typography variant="body2" sx={{ textAlign: 'center', width: '100%' }}>
                        No Details To Show
                      </Typography>
                    )}
                  </Card>
                )}
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
};

export default ScoreTable;