import { Box } from '@mui/system';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Navbar from './Navbar';
import UserProfile from './userProfile';
import TournamentList from './tournamentlist';
import AuthWrapper from './AuthWrapper';
import { toggleAuthWallOff, toggleAuthWallOn } from './../redux/auth/auth.slice';
import { useNavigate, useLocation } from 'react-router-dom';
import Match from './Matches';
import CreationForm from './Team-Creation/CreationForm';
import Footer from './footer';
import { ToastContainer } from 'react-toastify';
import AboutUsPage from '../pages/policies/AboutUs';
import PrivacyPolicy from '../pages/policies/privacyPolicy';
const Layout = () => {
  const [showMatches, setShowMatches] = useState(true);
  const [showUserSettings, setShowUserSettings] = useState(false);
  const [showTeam, setShowTeam] = useState(false);
  const [showTournamentList, setShowTournamentList] = useState(false);
  const [showaboutuspage, setShowAboutuspage] = useState(false);
  const [showprivacypolicy, setShowprivacypolicy] = useState(false);
  const { isAuthenticated, showAuthWall } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToDefault = () => {
    navigate('/', { replace: true });
  };

  const handlelogoClick = () => {
    navigate('/', { replace: true });
  };

  const handleBatClick = () => {
    navigate('/', { replace: true });
  };

  const handleAvatarClick = () => {
    if (!isAuthenticated) {
      dispatch(toggleAuthWallOn());
    } else {
      navigate('/user-profile');
    }
  };

  const handleAwardClick = () => {
    if (!isAuthenticated) {
      // Turn off the AuthWall
      dispatch(toggleAuthWallOff());

      // Navigate to the tournaments page
      navigate('/tournaments', { replace: true });

      // Dispatch toggleAuthWallOn AFTER the navigation is complete
    } else {
      // If the user is authenticated, just navigate to tournaments
      navigate('/tournaments');
    }
  };

  const handleTeamClick = () => {
    if (!isAuthenticated) {
      dispatch(toggleAuthWallOn());
    }
    navigate('/team-creation');
  };

  const handleInfoclick = () => {
    navigate('/about-us');
  };

  const handleprivacyClick = () => {
    navigate('/privacy-policy');
  };

  useEffect(() => {
    const currentPath = location.pathname;
    switch (currentPath) {
      case '/':
        setShowMatches(true);
        setShowUserSettings(false);
        setShowTeam(false);
        setShowTournamentList(false);
        setShowprivacypolicy(false);
        setShowAboutuspage(false);
        break;
      case '/user-profile':
        setShowUserSettings(true);
        setShowMatches(false);
        setShowTeam(false);
        setShowTournamentList(false);
        setShowprivacypolicy(false);
        setShowAboutuspage(false);
        break;
      case '/tournaments':
        setShowTournamentList(true);
        setShowMatches(false);
        setShowUserSettings(false);
        setShowTeam(false);
        setShowprivacypolicy(false);
        setShowAboutuspage(false);
        break;
      case '/team-creation':
        setShowTeam(true);
        setShowMatches(false);
        setShowUserSettings(false);
        setShowTournamentList(false);
        setShowprivacypolicy(false);
        setShowAboutuspage(false);
        break;
      case '/about-us':
        setShowAboutuspage(true);
        setShowMatches(false);
        setShowUserSettings(false);
        setShowTournamentList(false);
        setShowprivacypolicy(false);
        break;
      case '/privacy-policy':
        setShowprivacypolicy(true);
        setShowMatches(false);
        setShowUserSettings(false);
        setShowTournamentList(false);
        setShowAboutuspage(false);
        break;
      default:
        navigateToDefault();
        break;
    }
  }, [location.pathname, navigate]);

  return (
    <>
      <ToastContainer />
      <AuthWrapper>
        <div className="sticky top-0 z-50 ">
          <Navbar
            onlogoClick={handlelogoClick}
            onBatClick={handleBatClick}
            onAvatarClick={handleAvatarClick}
            onAwardClick={handleAwardClick}
            onTeamClick={handleTeamClick}
            onInfoclick={handleInfoclick}
            onPrivacyClick={handleprivacyClick}
          />
        </div>
        <div className="flex-1 pt-[0px]">
          <Box sx={{ pt: 0 }}>
            {showMatches ? (
              <Match />
            ) : showTournamentList ? (
              <TournamentList />
            ) : showUserSettings ? (
              <UserProfile />
            ) : showTeam ? (
              <CreationForm />
            ) : showaboutuspage ? (
              <AboutUsPage />
            ) : showprivacypolicy ? (
              <PrivacyPolicy />
            ) : null}
          </Box>
        </div>
        <Footer />
      </AuthWrapper>
    </>
  );
};

export default Layout;
